import { wsService } from "./wsService";
import sections from "@/constants/sections";
import ChequesState from "@/constants/chequesStates";

const section = sections.SECTION_CHECKS;

const BBVA = 17;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function changeState(param = {}) {
  return wsService.make_post_request(section + "/performe_action", param);
}

function getStates() {
  return wsService.make_get_request(section + "/get_states");
}

function rollbackState(param = {}) {
  return wsService.make_post_request(section + "/rollback_state", param);
}

function to_expire(param = {}) {
  return wsService.make_post_request(section + "/to_expire", param);
}

function getClassState(state) {
  switch (state) {
    case ChequesState.STATE_EN_CARTERA:
      return "state_cheque en_cartera";
    case ChequesState.STATE_DEPOSITADO:
      return "state_cheque depositado";
    case ChequesState.STATE_ACREDITADO:
      return "state_cheque acreditado";
    case ChequesState.STATE_COBRADO_VENTANILLA:
      return "state_cheque cobrado_por_ventanilla";
    case ChequesState.STATE_DEBITADO:
      return "state_cheque debitado";
    case ChequesState.STATE_RECHAZADO:
      return "state_cheque rechazado";
    case ChequesState.STATE_UTILIZADO:
      return "state_cheque utilizado";
    case ChequesState.STATE_VENCIDO:
      return "state_cheque vencido";

    default:
      return "state_cheque";
  }
}

function getStartIndex(bank) {
  if (bank == null) {
    return 0;
  }
  switch (bank.code) {
    case BBVA:
      return 1;
    default:
      return 0;
  }
}

function getAmount(bank, row, is_own = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return !is_own ? row[6].toString().replace("$", "") : row[5].replace("$", "");
    default:
      return "";
  }
}

function getNumber(bank, row, is_own = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return !is_own ? row[8] : row[7];
    default:
      return "";
  }
}

function getEmissionDate(bank, row, is_own = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return !is_own ? formatDate(row[3]) : formatDate(row[4]);
    default:
      return "";
  }
}

function getPaymentDate(bank, row, is_own = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return !is_own ? formatDate(row[4]) : formatDate(row[2]);
    default:
      return "";
  }
}

function getBank(bank, row, list_banks = [], is_own = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      if (row[2] == "BANCO SANTANDER RIO S.A.") {
        row[2] = "BANCO SANTANDER ARGENTINA S.A.";
      }
      return list_banks.filter((b) => b.clean_name == row[2])[0];
    default:
      return "";
  }
}

function getEmitterName(bank, row, is_own = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return row[0];
    default:
      return "";
  }
}

function formatDate(date) {
  //caso de fecha en formato objeto
  try {
    if (!isNaN(date.getTime())) {
      // Obtiene el año, el mes y el día de la fecha
      const año = date.getFullYear();
      const mes = String(date.getMonth() + 1).padStart(2, "0"); // Se agrega 1 porque los meses van de 0 a 11
      const dia = String(date.getDate()).padStart(2, "0");

      // Formatea la fecha en el formato YYYY-MM-DD
      return `${año}-${mes}-${dia}`;
    }
  } catch (e) {
    //console.log(e);
  }

  var parts;
  //caso de fecha en formato dd-mm-yyyy
  if (date.includes("-")) {
    // Dividir el string en día, mes y año
    parts = date.split("-");
    // Crear una nueva fecha con las partes en el orden deseado (año-mes-día)
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  }

  if (date.includes("/")) {
    // Dividir el string en día, mes y año
    parts = date.split("/");
    // Crear una nueva fecha con las partes en el orden deseado (año-mes-día)
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  }
}

export const checkService = {
  section,
  filter,
  remove,
  create,
  update,
  changeState,
  getStates,
  getClassState,
  rollbackState,
  to_expire,
  getStartIndex,
  getAmount,
  getNumber,
  getEmissionDate,
  getPaymentDate,
  getBank,
  getEmitterName,
};
